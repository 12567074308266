<template>
  <b-card-code>
    <div class="custom-search d-flex">
    <b-button variant="outline-primary" @click="showModal">
      Add new
    </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="all_public_menu"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'menu_icon_id'">
            {{ getIconName(props.row.menu_icon_id) }}
        </span>
        <span v-else-if="props.column.field === 'align'">
            {{ alings.find(item=>item.id===parseInt(props.row.align))?alings.find(item=>item.id===parseInt(props.row.align)).name:'' }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'is_active'">
          <template>
            <b-form-checkbox :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
          </template>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
             <b-button v-if="$can('remove','Version')" variant="danger" @click="remove(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['5','10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
        hide-footer size="lg"
        :title="model_mode==='add'?$t('Add new menu'):$t('Edit menu')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_or_update_form">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Menu Name') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Name')"
                    rules="required"
                >
                  <b-form-input
                      v-model="name"
                      name="name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Menu name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Menu Icon') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('menu icon')"
                  rules="required"
              >
                <select name="menu_icon_id" v-model.number="menu_icon_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(icon,key) in menu_icons" :value="icon.id" :key="key">{{icon.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Menu Position') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('position')"
                  rules="required"
              >
                <b-form-input v-model.number="position" type="number"
                              name="position"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="$t('Menu position')"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Menu Align') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('menu align')"
                  rules="required"
              >
                <select name="align" v-model.number="align_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(icon,key) in alings" :value="icon.id" :key="key">{{icon.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-2">
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BFormCheckbox, BPagination, BFormGroup, BFormInput, BFormSelect,BCardText,
  BModal, BRow, BCol,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
export default {
  name:'PublicMenu',
  components: {
    BCardCode,
    VueGoodTable,
    BFormCheckbox,BCardText,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,
    BRow, BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      menu_icon_id:null,
      position:0,
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Menu Icon',
          field: 'menu_icon_id',
        },
        {
          label: 'Child',
          field: 'public_sub_menus_count',
        },
        {
          label: 'Position',
          field: 'position',
        },
        {
          label: 'Align',
          field: 'align',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      align_id:0,
      alings:[
        {id:0, name:'Top'},
        {id:1, name:'Left'},
        {id:2, name:'Right'},
      ],
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
    }
  },
  methods:{
    getIconName(id){
      return this.$store.getters.menu_icon_by_id(id)?.name;
    },
    selectedRow(row){
      this.selected_row=row;
      this.name=this.selected_row.name;
      this.menu_icon_id=this.selected_row.menu_icon_id;
      this.position=this.selected_row.position;
      this.align_id=this.selected_row.align;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_or_update_form);
          if (this.model_mode ==='add'){
            apiCall.post('/public/menu/store',data).then((response)=>{
              this.hideModal();
              this.$store.dispatch('GET_ALL_MENUS');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/public/menu/update${this.selected_row.id}`,data).then((response)=>{
              this.hideModal();
              this.$toaster.success(response.data.message);
              this.$store.dispatch('GET_ALL_MENUS');
              this.$store.dispatch('GET_ALL_MENU_WITH_SUBMENU');
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    async statusChange(id){
      await apiCall.put(`/public/menu/status/change${id}`).then((response)=>{
        this.$store.dispatch('GET_ALL_MENUS');
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_MENUS');
      });
    },
    remove(id){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/public/menu/remove${id}`).then((response)=>{
              this.$store.dispatch('GET_ALL_MENUS');
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Menu item is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.name='';
      this.menu_icon_id=null;
      this.position=0;
      this.align_id=0;
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['all_public_menu','menu_icons']),
  },
  created() {
    if(this.all_public_menu <1) this.$store.dispatch('GET_ALL_MENUS');
    if(this.menu_icons <1) this.$store.dispatch('GET_ALL_MENU_MENU_ICON');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>